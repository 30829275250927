<template>
  <div class="manual-bill-detail">
    <div class="video">
      <img src="@/assets/manual/video10.png" alt="云链壹号用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>详单查询</h1>
        <div class="box-content">
          1、点击右上角头像
          <div>
            2、点击“账户详情”
          </div>
          <div class="img-box">
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="云链壹号用户手册">
            </viewer>
          </div>
          3、选择“详单查询”菜单，可以查询不同交易类型的详单信息：
          <div class="box-content-bg">
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>作业消费</div>
            </div>
            <div class="space-tab">
              支持根据多个条件进行查询：可以根据交易流水号模糊搜索，选择最后消费时间段查询，还可以勾选作业状态和交易状态进行筛选。
            </div>
            <div class="img-box">
              <viewer :images="imageUrl2" :options="options">
                <img :src="imageUrl2" alt="云链壹号用户手册">
              </viewer>
            </div>
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>AI消费</div>
            </div>
            <div class="space-tab">
              支持根据多个条件进行查询：可以根据交易流水号模糊搜索，选择消费时间段查询，还可以勾选消费状A态进行筛选。
            </div>
            <div class="img-box">
              <viewer :images="imageUrl3" :options="options">
                <img :src="imageUrl3" alt="云链壹号用户手册">
              </viewer>
            </div>
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>磁盘配额订单</div>
            </div>
            <div class="space-tab">
              支持根据多个条件进行查询：可以根据交易流水号模糊搜索，选择最后消费时间段查询，还可以勾选交易状态和状态进行筛选。
            </div>
            <div class="img-box">
              <viewer :images="imageUrl4" :options="options">
                <img :src="imageUrl4" alt="云链壹号用户手册">
              </viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/invoice1-big.png'),
      imageUrl2: require('../assets/manual/detail2-big.png'),
      imageUrl3: require('../assets/manual/detail3-big.png'),
      imageUrl4: require('../assets/manual/detail4-big.png')
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.manual-bill-detail{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
  .box-content-bg{
    padding-right: 12px;
    .img-box{
      padding-left: 12px;
    }
    .space-tab{
      padding: 0 11px 8px 14px;
    }
  }
}
</style>
