<template>
  <div class="manual-month">
    <div class="video">
      <img src="@/assets/manual/video9.png" alt="云链壹号用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>月报</h1>
        <div class="box-content">
          <div>
            1、点击右上角头像
          </div>
          <div>
            2、点击“账户详情”
          </div>
          <div class="img-box">
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div>
            3、选择“账单概览”菜单，点击“月报”，实现根据月份进行查询汇总，包括充值明细和交易明细；交易明细包含作业消费、AI消费和磁盘配额订单。
          </div>
          <div class="img-box">
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="云链壹号用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/invoice1-big.png'),
      imageUrl2: require('../assets/manual/month2-big.png')
    }
  }
}
</script>

<style scoped lang="less">
.manual-month{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
