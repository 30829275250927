<template>
  <div class="manual-bill-view">
    <div class="video">
      <img src="@/assets/manual/video11.png" alt="云链壹号用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>账单概览</h1>
        <div class="box-content">
          <div>
            1、点击右上角头像
          </div>
          <div>
            2、点击“账户详情”
          </div>
          <div>
            3、选择“账单概览”菜单，账单概览列表支持根据多个条件进行查询：可以根据交易流水号模糊搜索，选择提交时间段查询，还可以勾选交易类型进行筛选。
          </div>
          <div class="img-box">
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="云链壹号用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/view1-big.png')
    }
  }
}
</script>

<style scoped lang="less">
.manual-bill-view{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
