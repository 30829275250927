<template>
  <div class="icon-section">
    <appIcon v-for="(item,index) in icons" :key="item.id" :item="item" :grid="grid" :cell="cell" :order="index" />
  </div>
</template>

<script>
import appIcon from './Icon.vue'
// import { queryDesktopApp } from '@/api/application'
export default {
  name: 'IconGrid',
  components: {
    appIcon
  },
  data() {
    return {
      // icons: [],
      loading: false,
      grid: { // 网格行列个数
        row: 0,
        col: 0
      },
      cell: { // 每个单元格大小
        height: 80,
        width: 90
      }
    }
  },
  computed: {
    icons() {
      var icons = []
      this.$store.state.core.applications.forEach(item => {
        if (item.link) {
          if (item.visible === 1) {
            icons.push(item)
          }
        }
      })
      this.$nextTick(() => {
        if (icons.length > 0) {
          this.$emit('loadHandle', this.loading)
        }
      })
      return icons
    }
  },
  created() {
    this.getGrid()
    // this.getAppData()
  },
  methods: {
    getGrid() {
      const w = document.body.clientWidth
      const h = document.body.clientHeight * 0.90
      this.grid.row = Math.ceil(h / this.cell.height)
      this.grid.col = Math.ceil(w / this.cell.width)
    }
  }
}
</script>

<style>
</style>
