<template>
  <div class="manual-forget-psd">
    <div class="video">
      <!-- <img src="@/assets/manual/video1.png" alt="云链壹号用户手册"> -->
      <video src="@/assets/viod/password.mp4" controls="controls" />

    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>密码找回</h1>
        <div class="box-content">
          1：打开平台首页<span class="mask-color">(https://console.hpc.jengcloud.com)</span>
          <div class="space-tab">点击“忘记密码”</div>
          2：输入要找回密码的账号
          <div class="img-box">
            <!-- <img src="@/assets/manual/psd1.png" alt="云链壹号用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="云链壹号用户手册">
            </viewer>
          </div>
          3：选择找回方式
          <div class="box-content-bg">
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>手机验证码方式</div>
            </div>
            <div class="space-tab">（1）输入手机号获取验证码</div>
            <div class="space-tab tips">
              注：必需使用账号绑定的手机号进行验证。如忘记手机号，可使用“联系客服方式”进行找回密码
            </div>
            <div class="space-tab">
              （2）输入手机验证码、新密码，提交后即可使用新密码登录平台
            </div>
            <div class="img-box">
              <viewer :images="imageUrl2" :options="options">
                <img :src="imageUrl2" alt="云链壹号用户手册">
              </viewer>
              <!-- <img src="@/assets/manual/psd2.png" alt="云链壹号用户手册" @click="showImagePreview(2)"> -->
            </div>
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>联系客服方式</div>
            </div>
            <div class="space-tab">
              （1）填写基础信息
            </div>
            <div class="space-tab">
              （2）填写补充资料，提交后获取手机验证码
            </div>
            <div class="space-tab">
              （3）填写手机验证码后提交
            </div>
            <div class="img-box">
              <viewer :images="imageUrl3" :options="options">
                <img :src="imageUrl3" alt="云链壹号用户手册">
              </viewer>
              <!-- <img src="@/assets/manual/psd3.png" alt="云链壹号用户手册" @click="showImagePreview(3)"> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/psd1-big.png'),
      imageUrl2: require('../assets/manual/psd2-big.png'),
      imageUrl3: require('../assets/manual/psd3-big.png')
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.manual-forget-psd{
video{
  width: 100%;
  height: 100%;
}
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
  .box-content-bg{
    padding-right: 12px;
    .img-box{
      padding-left: 12px;
    }
    .space-tab{
      padding: 0 11px 8px 14px;
    }
  }
}
</style>
