<template>
  <el-drawer v-model="drawer" :title="标题" :size="size" :with-header="false" @open="openDrawer" @close="closeDrawer">
    <div class="title">
      <span class="problem-title">问题反馈</span>
      <svg-icon icon-class="close" @click="closeDrawer" />
    </div>
    <div class="problem-content">
      <el-form ref="questionRefs" :model="questionData" :rules="questionRules" :label-position="position">
        <el-form-item label="问题类型" prop="type">
          <el-select v-model="questionData.type" size="small" placeholder="请选择内容">
            <el-option label="应用类" :value="1" />
            <el-option label="使用类" :value="2" />
            <el-option label="账户类" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="问题描述" prop="desc">
          <el-input
            v-model="questionData.desc"
            maxlength="500"
            show-word-limit
            :autosize="{ minRows: 13}"
            resize="none"
            type="textarea"
            placeholder="请输入反馈内容,最多500字"
          />
        </el-form-item>
      </el-form>
      <div class="problem-point">
        您可以致电客服电话0531-89009176（周一到周五9:00-17:00） 或发送邮件至nobug@jengcloud.com我们将尽快回复您的问题
      </div>
    </div>
    <div class="problem-submit">
      <el-button type="primary" :disabled="submitDisable" size="small" @click="submit">提交</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { question } from '@/api/application'
import { ElMessage } from 'element-plus'
export default {
  name: 'ProblemFeedback',
  data() {
    return {
      submitDisable: false,
      drawer: false,
      questionData: {
        type: '',
        desc: ''
      },
      problemType: ['应用类', '使用类', '账户类'],
      position: 'top',
      size: '407px !important',
      questionRules: {
        type: [
          { required: true, message: '请选择问题类型', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请输入问题描述', trigger: 'blur' },
          { min: 1, max: 500, message: '最大长度500个字符', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      email: state => state.user.email,
      mobile: state => state.user.mobile
    })
  },
  methods: {
    openProblemDrawer() {
      if (this.$store.state.baidu.keyboard) {
        this.$store.state.baidu.keyboard.onkeydown = null
        this.$store.state.baidu.keyboard.onkeyup = null
      }
      if (this.$store.state.windowImage.keyboard) {
        this.$store.state.windowImage.keyboard.onkeydown = null
        this.$store.state.windowImage.keyboard.onkeyup = null
      }
      this.drawer = true
    },
    openDrawer() {
      this.$nextTick(() => {
        this.$refs.questionRefs.resetFields()
      })
    },
    closeDrawer() {
      this.drawer = false
      this.submitDisable = false
      this.questionData.desc = ''
      this.questionData.type = ''
    },
    submit() {
      this.$refs.questionRefs.validate((valid) => {
        if (valid) {
          this.submitDisable = true
          this.$nextTick(() => {
            const problemData = {
              descreption: this.questionData.desc,
              email: this.email,
              mobile: this.mobile,
              questionType: this.problemType[this.questionData.type - 1]
            }
            question(problemData).then(result => {
              if (result.meta.status === 201) {
                ElMessage({
                  type: 'success',
                  message: '提交成功'
                })
                this.submitDisable = false
                this.closeDrawer()
                this.drawer = false
              } else {
                ElMessage({
                  type: 'error',
                  message: '提交失败'
                })
                this.submitDisable = false
              }
            }).catch(() => {
              this.submitDisable = false
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  height: 49px;
  font-size: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 160px;
  border-bottom: 1px solid #E7E7E7;
}
.problem-title{
  // padding-right: 200px;
}
.problem-content {
  padding: 24px;
  .problem-text {
    color: #303133;
    font-size: 16px;
  }
  .m-t-24 {
    margin-top: 24px;
  }
  .problem-point {
    position: absolute;
    top: calc(100vh - 156px);
    width: 350px;
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #909399;
    line-height: 20px;
  }
}
.problem-submit {
    position: absolute;
    height: 48px;
    width: 100%;
    // background: red;
    bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
    border-top: 1px solid #E7E7E7;
    ::v-deep .el-button--primary {
      background: transparent !important;
      color: #437aec !important;
    }
}
.el-select {
  width: 100%;
}

</style>
