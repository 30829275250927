<template>
  <div class="wallpaper-section">
    <div class="wallpaper-section" @mousedown="onMousedown($event)">
      <!-- <img :src="wallpaper" alt=""> -->
      <video id="myVideo" autoplay muted>
        <source src="@/assets/3.webm" type="video/webm">
        您的浏览器不支持 WebM 格式视频。
      </video>
    </div>
    <!-- <div class="mouse-action-section"><div class="selection-section" v-show="selection.startSelection"></div></div> -->
  </div>
</template>

<script>
export default {
  name: 'Wallpaper',
  data() {
    return {
      selection: {
        startSelection: false,
        startPosition: {},
        currentPosition: {},
        topLeft: null,
        bottomRight: null
      }
    }
  },
  computed: {
    wallpaper() {
      return this.$store.state.core.wallpaper
    }
  },
  mounted() {
    const videoElement = document.getElementById('myVideo')
    if (videoElement) {
      videoElement.addEventListener('ended', function() {
        this.pause() // 暂停视频
      }, false)
    }
  },
  methods: {
    onMousedown(event) {
      this.$store.commit('core/SELECT_ICON', '') // 清空 图标选择
      if (event.button == 2) {
        this.$store.commit('core/SET_CONTEXT_MENU', { x: event.clientX, y: event.clientY, type: 'wall' })
      } else {
        this.$store.dispatch('core/sidebarSwitch', false)
        this.$store.commit('core/CLEAN_CONTEXT_MENU')
      }
    }
  },
  ready: function() {}
}
</script>

<style lang="less">
.wallpaper-section {
	height: 100%;
	// background-size: cover;
	// background: no-repeat center;
	// background: #42b983;
  img {
    width: 100vw;
    height: 100vh;
    -webkit-user-drag: none;
  }
  video{
    object-fit: fill;
    width: 100vw;
    height: 100vh;
  }
}
</style>
