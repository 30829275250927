<template>
  <div class="manual-job">
    <div class="video">
      <img src="@/assets/manual/video7.png" alt="云链壹号用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>作业管理</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/job1.png" alt="云链壹号用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div class="bold-title">
            1、基本功能
          </div>
          <div class="space-tab">
            右键某个作业选择对应操作，或者单击某个作业的这一行即可选中该作业并点击列表上方的停止、查看日志、查看窗口图传、恢复/挂起、查看详情进行相应的操作
          </div>
          <div class="img-box">
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div class="img-box">
            <viewer :images="imageUrl5" :options="options">
              <img :src="imageUrl5" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div class="space-tab">
            点击作业ID，可查看作业详情
          </div>
          <div class="img-box">
            <viewer :images="imageUrl6" :options="options">
              <img :src="imageUrl6" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div class="bold-title">
            2、进阶功能-Terminal
          </div>
          <div class="space-tab">
            选择terminal图标并点击打开
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/job3.png" alt="云链壹号用户手册" @click="showImagePreview(3)"> -->
            <viewer :images="imageUrl3" :options="options">
              <img :src="imageUrl3" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div class="bold-title">
            3、进入界面后进行相关操作
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/job4.png" alt="云链壹号用户手册" @click="showImagePreview(4)"> -->
            <viewer :images="imageUrl4" :options="options">
              <img :src="imageUrl4" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div class="img-box">
            后续可以参照<a href="https://hpc-help.jengcloud.com/docs/shanhe1/shanhe1-1ej62rl5hg5aj" target="_blank">集群方式使用</a>，软件可以联系支持人员进行配置
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/job1-big.png'),
      imageUrl2: require('../assets/manual/job-bg2.png'),
      imageUrl3: require('../assets/manual/job2-big.png'),
      imageUrl4: require('../assets/manual/terminal-big.png'),
      imageUrl5: require('../assets/manual/job-bg3.png'),
      imageUrl6: require('../assets/manual/job-bg4.png')
    }
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else if (val === 2) {
        image.src = this.imageUrl2
      } else if (val === 3) {
        image.src = this.imageUrl3
      } else {
        image.src = this.imageUrl4
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else if (val === 2) {
          previewImage.src = this.imageUrl2
        } else if (val === 3) {
          previewImage.src = this.imageUrl3
        } else {
          previewImage.src = this.imageUrl4
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-job{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
