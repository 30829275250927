<template>
  <div class="manual-terminal">
    <div class="video">
      <img src="@/assets/manual/video7.png" alt="云链壹号用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>命令行方式提交作业</h1>
        <div class="box-content">
          <div>
            1、双击作业管理或者右击作业管理选择启动
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/terminal1.png" alt="云链壹号用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div>
            2、选择命令行窗口图标，点击打开
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/terminal2.png" alt="云链壹号用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div>
            3、进入界面后进行相关操作
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/terminal3.png" alt="云链壹号用户手册" @click="showImagePreview(3)"> -->
            <viewer :images="imageUrl3" :options="options">
              <img :src="imageUrl3" alt="云链壹号用户手册">
            </viewer>
          </div>
          <div class="img-box">
            后续可以参照<a href="https://hpc-help.jengcloud.com/docs/shanhe1/shanhe1-1ej62rl5hg5aj" target="_blank">集群方式使用</a>，软件可以联系支持人员进行配置
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/terminal1-big.png'),
      imageUrl2: require('../assets/manual/terminal2-big.png'),
      imageUrl3: require('../assets/manual/terminal-big.png')
    }
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else if (val === 2) {
        image.src = this.imageUrl2
      } else {
        image.src = this.imageUrl3
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else if (val === 2) {
          previewImage.src = this.imageUrl2
        } else {
          previewImage.src = this.imageUrl3
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-terminal{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
